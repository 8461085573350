<template>
  <v-card>
    <v-card-text class="pa-0">
      <template>
        <v-toolbar card color="white">
          <v-text-field
            flat
            solo
            prepend-icon="search"
            placeholder="Filtrar"
            v-model="search"
            hide-details
            class="hidden-sm-and-down"
          ></v-text-field>
          <router-link :to="{ name: 'cadastro-fornecedor' }" class="nounderline">
            <v-btn icon>
              <v-icon>add_circle_outline</v-icon>
            </v-btn>
          </router-link>
        </v-toolbar>
        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          :search="search"
          :items="fornecedor"
          :rows-per-page-items="[10, 25, 50, { text: 'All', value: -1 }]"
          class="elevation-1"
        >
          <template v-slot:items="props">
            <td>{{ props.item.id }}</td>
            <td>{{ props.item.nome }}</td>
            <td>{{ props.item.telefone1 }}</td>
            <td>{{ props.item.email }}</td>
            <td class="text-xs-right">
              <router-link :to="{ name: 'edit-fornecedor', params: { id: props.item.id } }" class="nounderline">
                <v-btn depressed icon dark color="primary" small>
                  <v-icon small>edit</v-icon>
                </v-btn>
              </router-link>
              <router-link :to="{ name: 'view-fornecedor', params: { id: props.item.id } }" class="nounderline">
                <v-btn depressed icon dark color="primary" small>
                  <v-icon small>remove_red_eye</v-icon>
                </v-btn>
              </router-link>
            </td>
          </template>
        </v-data-table>
      </template>
      <v-divider></v-divider>
    </v-card-text>
  </v-card>
</template>

<script>
import { ServicoFornecedor } from "../../servicos/servicoFornecedor"
const servicoFornecedor = new ServicoFornecedor()
export default {
  data() {
    return {
      search: "",
      fornecedor: [],
      headers: [
        {
          text: "Código",
          align: "left",
          value: "id"
        },
        { text: "Nome", align: "left", value: "nome" },
        { text: "Telefone", align: "left", value: "telefone1" },
        { text: "E-mail", align: "left", value: "email" },
        { text: "", value: "action", align: "right" }
      ]
    }
  },
  created() {
    servicoFornecedor.buscarFornecedor().then(
      res => {
        if (res.status === 200) {
          this.fornecedor = res.data
        }
      },
      // eslint-disable-next-line
      err => console.log(err)
    )
  }
}
</script>
<style>
a.nounderline:link {
  text-decoration: none;
}
</style>
