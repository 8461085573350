<template>
  <div id="pageFornecedor">
    <v-container grid-list-x2 fluid>
      <v-layout row wrap justify-center>
        <v-flex lg11 sm12 xs12>
          <fornecedor-table></fornecedor-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import FornecedorTable from "@/components/fornecedor/tableFornecedor"
export default {
  components: {
    FornecedorTable
  }
}
</script>
